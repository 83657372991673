
.ModalContainer {
  .Complete {
    
    .CompleteNav {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      width: 100%;
    }
  }
}

@media print {
  .ResultModal {
    display: block !important;
  }
}
.ResultModal {
  display: none;
  .Complete {
    .CompleteNav {
      display: none;
    }
  }
}
