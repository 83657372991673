.Restart {
  text-align: center;

  .RestartNav {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
  button.button {
    text-transform: uppercase;
  }
}