@import "./Colors.scss";

.Start.centered {
  font-size: 16px;
  padding: 1em 2em 2em;
  background-color: white;
  word-break: break-word;
  text-align: center;
  font-weight: 500;

  .Title {
    display: flex;
    justify-content: center;
    h1 {
      position: relative;
      font-size: 4em;
      padding: 0;
      margin: 0;
    }
    .trademark {
      display: none;
      position: absolute;
      height: fit-content;
      font-size: 0.5em;
      bottom: -0.09em;
      right: -0.75em;
    }
  }
  h2, h4 {
    font-weight: 500;
  }
  a {
    display:block;
    color: $green;
  }

  button.button {
    margin-top: 5em;
  }
}
