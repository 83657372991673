@import "./Colors.scss";

.EmptyLine {
  height: 1em;
  border-bottom: 1px solid black;

  &.prepop {
    border-bottom-color:transparent;
  }
}
@media print {
  html, body {
    background-color: transparent;
  }
}

.Results.centered {
  font-size: 16px;
  padding: 1em 2em 2em;
  background-color: white;

  @media print{
    font-size: 12pt;
    padding: 0;

    .PrintHead {
      display:flex;
      justify-content: space-between;
      margin-bottom: 1em;
    }

    h1 {
      font-size: 36px;
      margin-bottom: 5px;
    }
    
    header {
      margin-bottom: 0;
      padding-bottom: 0;
      
      .centered {
        padding: 1rem 2rem;
      }
    }
  }

  h2 {
    margin-bottom: 0.25em;
    font-size: 1.25em;
  }

  .MedicalProfile > h2 {
    font-size: 1.5em;
  }

  h3 {
    font-size: 1em;
    margin: 0;
  }

  h4 {
    margin:0;
    margin-top: 0.5em;
    font-weight: 500;
    text-decoration: underline;
  }

  .ResultsHeader {
    display: flex;
  }

  @media print {
    .Stamp {
      width: 2.5in;
      height: 1.5in;
      margin: 0px 15px;
      border: 1pt dashed black;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  .PatientProfile {
    font-size: 1.5em;
    border: 2pt solid $green;
    border-left: none;
    border-right: none;
    padding: 7px;
  }
  
  .Healthy {
    margin-left: 1rem;
  }

  pre {
    white-space: pre-line;
    word-wrap: break-word;
    overflow-wrap: break-word;
    letter-spacing: 0pt;
  }

  .AssessorComments {
    pre, p {
      padding-top: 0;
      margin-top: 0;
      padding-left: 1.5em;
      overflow-wrap: break-word;
      word-wrap: break-word;
    }
  }

  #question120 {
    padding-left: 0.5em;
    >p{
      margin: 0;
      padding-left: 0.5em;
    }
  }

  #questionBMIExtremes,
  #questionSevereMalourishment,
  #question121,
  #question122,
  #question124,
  #questionAirEntry,
  #questionAdventitiousSounds,
  #questionRespiratoryOther,
  #question127,
  #question128{
    padding-left: 1.5em;
  }

  .ContactInformation {
    margin-top: 1em;
    border-top: 2px solid $green;
    h2 {
      margin-bottom: 0.8em;
    }
    

    .FieldItemResult {
      font-weight: 500;
      margin-left: 3em;
      padding:0;
    }

    .SignatureLine{
      display: flex;
      
      > div:first-child {
        flex: 1
      }

      .ProviderDate {
        margin-top: 2em;
      }

      .ProviderSignature, .ProviderDate {
        padding-left: 0.5em;
        text-align: right;
        display: flex;
        >div:first-child {
          flex: 1;
        }
        >div:nth-child(2) {
          text-align: center;
          margin-left: 0.5em;
          width: 2.5in;
          border-bottom: 1px solid black;
        }
      }
    }
  }

  .PatientInstructionsTitle {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items:flex-end;

    h2 {
      margin-top: 0;
      margin-bottom: 0;
      font-size: 20pt;
    }
    img {
      max-width: 1.5in;
      
    }
  }

  .MedicationResults {
    >pre, p {
      margin: 0;
      padding: 0;
      font-size: 12pt;
      margin-bottom: -6px;
      min-height: 10em;
      padding-right: 2em;
    }
    
    >.EmptyLine {
      margin-top: 3px;
    }
    @media print {
      width: 50%;
      >li {
        list-style: circle;
      }
    }
  }

  .textarea, textarea {
    display: block;
    min-height: 6em;
    width: 99%;
    border: 1px solid black;
    resize: vertical;
    height: max-content;
    overflow: hidden;

    @media print {
      resize: none;
      border: none;
      overflow:visible;
      height: auto;
    }
  }

  .lowerNav {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;

    > div {
      display: flex;
      text-align: center;
      justify-content: center;
      width: 33.33%;
      &:first-child {
        justify-content: flex-start;
      }
      &:last-child {
        justify-content: flex-end;
      }
    }

    .NavLinks {
      display: flex;
      flex-direction: column;
      text-align: right;
    }
  }

  .ResultsSeparator {
    &::before {
      content: "-";
      font-size: 1em;
    }
    margin: auto 1em;
  }

  .ResultsItemCheckbox {
    display: flex;
  }
  
  .lowerNav {
    margin-top: 40px;
  }
  .FieldItemResult, .ResultIndent {
    font-weight: bold;
    margin-left: 3em;
  }
  .ResultIndent {
    width: 0px;
    height: 1px;
    float: left;
  }
  .ResultsItem {
    grid-gap: 0.25em;
    padding: 0.25em 1em;
    justify-content: space-between;

    &.ResultsItemOdd {
      background-color: #ccc;
    }

    >h4 {
      text-align: left;
      margin: auto 0;
      grid-column-start: 1;
      grid-column-end: 1;
    }

    >.ResultsItemValue {
      grid-column-start: 2;
      grid-column-end: 2;
      text-align: left;


      >.ResultsItemGrid {
        display: grid;

        >div {
          text-align: left;
          grid-column-start: 1;
          grid-column-end: 1;
        }

        >div:nth-child(2n) {
          text-align: right;
          grid-column-start: 2;
          grid-column-end: 2;
        }
      }
    }
  }
}