@import "./Colors.scss";
@import "./Helpers.scss";

.FormItems {
  font-size: 14px;
  button{
    font-size: 14px;
  }

  .centered {
    background-color: white;
    padding: 1rem 2rem 2rem;
    
    @media screen and (max-width: 640px) {
      padding: 1rem 0 2rem;
    }
  }

  h2 {
    margin-left: 2rem;
  }

  input {
    font-size: 14px;
  }

  .FormNavigation {
    display: flex;
    justify-content: space-between;
    margin: 0 2rem;
    font-size: 14px;

    input.button {
      margin-left: 0.5rem;
    }
    >div {
      width: 30%;
      &:last-child {
        text-align: right;
      }
    }

    .FormTips {
      margin: 0;
      display: flex;
      justify-content: center;
      align-items: center;
  
      a {
        text-align: center;
      }
    }

    @media screen and (max-width: 500px) {
      flex-direction: column;
      justify-content: center;
      align-items: center;
      input.button {
        margin-left: 0;
      }
      >div {
        display: flex;
        justify-content: space-between;
        text-align: center;
        width: 100%;
        margin-top: 1em;
        &:last-child {
          text-align: center;
          justify-content: space-around;
        }
      }
    }
  }

  .FormItem {
    padding: 1rem;
    margin: 1rem;
    background-color: #eee;
    border: 1px solid #ccc;

    label {
      margin-right: 0.5rem;
      padding-left: 0.125rem;
    }
  }

  input[type='checkbox'] {
    height: 14px;
    width:14px;
    min-width: 14px;
  }
  input[type='radio'] {
    font-size: 18px;
    height: 18px;
    width: 18px;
    min-width: 18px;
    margin: 3px;
  }

  .FormBoolean,.FormBooleanDetails,.FormRadioOption,.FormCheckboxOption {
    display: flex;
    align-items: center
  }

  .FormGrid {
    display: flex;
    flex-direction: column;
  }

  .FormGridRow {
    display: flex;
    border-top: 1px $green solid;
    margin-top: 4px;
  }

  .FormGridHeader {
    display: flex;
    align-items: center;
    text-align: center;
  }

  .FormGridHeaderItem {
    flex: 1;
    margin-bottom: 0.25rem;
    max-width: 100px;
    &:first-child {
      max-width: 500px;
    }
  }

  .FormGridRow {
    padding: 0.1em 0;
    > label, 
    > input {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      align-self: center;
      margin: 0;
      max-width: 100px;
      &:first-child {
        max-width: 500px;
      }
    }
    >  input {
      padding: 0;
      margin: auto 0;
      height: 1.2em;
    }
    > label {
      justify-content: flex-start;
    }
  }

  .FormGridRowEven {
    // background-color: $greenHighlight;
    // color: white;
  }

  .FormFields {
    .FormFieldQuestion {
      display: flex;
      margin: 0.5rem 0;

      label {
        flex: 1;
        max-width: 200px;
      }
      
      .react-tel-input .form-control {
        width: 100%;
      }
      input {
        flex: 4;
        
      }
    }
    .FormFieldTelephone {
      flex: 4;
    }
  }

  .FormCheckboxOption input {
    margin-right: 0.5rem;
  }

  .Details {
    margin-top: 1rem;
    display: flex;
    justify-content: space-between;

    label {
      margin-left: 0.25rem;
    }

    input {
      flex-grow: 1;
    }
  }

  textarea {
    width: 100%;
    min-height: 100px;
  }
}
