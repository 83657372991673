@import "./Colors.scss";

$maxWidth: 960px;

.centered {
  max-width: $maxWidth;
  margin: 0 auto;
}

.button {
  display: inline-block;
  background-color: $green;
  border: 1px solid $green;
  color: white;
  padding: 0.5rem 1rem;
  font-weight: bold;
  cursor: pointer;
  border-radius: 0;

  &:hover {
    background-color: $greenHighlight;
  }

  &[disabled] {
    background-color: #ccc;
    color: #999;
    cursor: not-allowed;
    border: 1px solid #999;
  }
}