@import "./Colors.scss";
@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,400;0,700;1,400;1,700&display=swap');

html,
body {
  margin: 0;
  min-height: 100vh;
  font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: $green;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.ReactModal__Overlay--after-open {
  display: flex;
  justify-content: center;
  align-items: center;
}
.Modal {
  overflow:auto;
  max-height: 100%;
}
.ModalContainer {
  max-width: 640px;
  height: fit-content;
  border: 1px solid black;
  background-color: $green;
  padding: 0.5rem;
  > div {
    background-color: white;
    padding: 2rem;
  }

  .BackContainer {
    margin-top: 1em;
  }

  &.HighRisk {
    max-width: unset;
  }
  &.Questions,&.Acknowledgements {
    li {
      list-style-type: none;
      margin-left: 1em;
    }
    .QContact {
      font-style: italic;
      margin-bottom: 1em;
    }
  }
  &.Acknowledgements {
    li {
      margin-bottom: 1em;
      list-style-type:square;
    }
    .PossibleBy {
      font-style: italic;
      margin-bottom: 1em;
    }
  }
}

.columns {
  display: flex;
  justify-content: space-between;
}

.ModalLinks {
  margin: 1em 0;
  display: flex;
  justify-content: flex-end;
  a {
    color: $green;
  }
  >div {
    text-align: right;
    a:not(:first-child){
      margin-top: 0.5em;
    }
  }
}

.onlyprint {
  visibility: hidden;
  display: none;
}

.ReactModal__Html--open,
.ReactModal__Body--open {
  overflow: hidden; /* prevents background page from scrolling when the modal is open */
}

.hide {
  display: none;
}

@page {
  margin: 0.75in;
  margin-bottom: 0.75in;
}
@media print {
  .ReactModalPortal {
    display: none !important;
  }
  .noprint {
    visibility: hidden !important;
    display: none !important;
  }
  .onlyprint {
    visibility: visible;
    display: block;
  }
  .avoidbreak {
    break-inside: avoid;
  }
}
