.HighRisk {

  > div {
    margin: 0 1rem;
    flex: 1
  }

  .HighRiskList {
    display: grid;
    grid-gap: 0 1rem;
    max-width: 1200px;
    margin: 0 auto;

    @media screen and (min-width: 780px) {
      grid-template-columns: repeat(2, 1fr); 
    }
    @media screen and (min-width: 1080px) {
      grid-template-columns: repeat(3, 1fr); 
    }

    > div {
      h3 {
        margin-top: 0;
      }

      ul {
        padding-left: 1.5rem
      }
    }
  }
}