@import "../Colors.scss";
@import "../Helpers.scss";

header {
  .centered {
    display:flex;
    justify-content: space-between;
    align-items: center;
    background-color: white;
    padding: 2rem;
  }

  .Logos {
    a {
      display: inline-block;
      margin-right: 1rem;
      font-size: 18px;
      font-weight: bold;
      color: black;
      text-decoration: none;
      display: inline-flex;
      align-items: center;
    }

    img {
      max-width: 150px;
    }

    span {
      margin-left: 1rem;
    }
  }

  .Title {
   font-size: 20px;
   font-weight: bold;   
  }
}